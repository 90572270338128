<template>
  <nav id="header-bar">
    <div class="main-comps">
      <img class="logo" src="@/assets/AssessrLogo.svg" />
      <div class="count-down">
        <div class="progres1" id="p1">
          <div class="progres-bar1" id="timer-bar"></div>
        </div>
        <div class="time">
          <b>Time:</b>
          <vue-countdown :time="milliseconds" @end="onCountdownEnd" @abort="stopCountdown" :autoStart="false"
            v-slot="{ hours, minutes, seconds }" ref="countdown">
            {{ hours }}:{{ minutes }}:{{ seconds }}
          </vue-countdown>
        </div>

        <div class="progres">
          <div class="progres-bar" ref="elaspedTime" role="progressbar" aria-valuenow="0" aria-valuemin="0"></div>
        </div>
      </div>

      <button data-toggle="modal" data-target="#finishExamModal" id="hidden-btn" data-backdrop="static"
        data-keyboard="false"></button>
      <button data-toggle="modal" data-target="#startExamModal" id="hidden-btn-2" data-backdrop="static"
        data-keyboard="false"></button>
      <button id="finish-exam" @click="openFinishExam()" class="finish-btn">
        Finish Exam
      </button>
      <button @click="logout" id="exit-all" class="btn-hidden exit-btn">
        Exit
      </button>
      <div class="
          finish-menu
          shadow-lg
          finish-exam
          scale-out
          new-fab
          scale-transition
        ">
        <p>Are you sure you want to finish this exam?</p>
        <b>This action is not reversable</b>
        <div class="action-buttons">
          <button @click="openFinishExam()" class="no">No</button>
          <button @click="openResultsModal()" class="yes">Yes</button>
        </div>
      </div>
    </div>
  </nav>
  <div class="modal fade" id="finishExamModal" tabindex="-1" role="dialog" aria-labelledby="finishExamModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="finishExamModalLabel">Your Results</h5>
          <!-- <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-body">
          <div v-if="loadingResults" class="loader">
            <div class="calc-results">
              <p>Calculating Results...</p>
            </div>
            <RoundLoader />
          </div>
          <div v-else>
            <div class="score">
              <p>Your Score:</p>
              <span class="myscore">{{ autoMarks.userTotal }}</span>/ {{ autoMarks.examTotal }}
            </div>
            <div class="note">
              <p>
                Please note this might not be your final result as some
                questions might be manually marked
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="!loadingResults" type="button" class="mod-btn review" data-dismiss="modal"
            @click="reviewExam()">
            Review
          </button>
          <button @click="closeResultsDialog" v-if="!loadingResults" type="button" class="mod-btn exit">
            Exit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="startExamModal" tabindex="-1" role="dialog" aria-labelledby="startExamModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="startExamModalLabel">Begin Exam</h5>
        </div>
        <div class="modal-body">
          <div class="score">
            <p>{{ examTitle }}</p>
          </div>
          <div class="note">
            <p>
              Answer as many questions as you can <br />
              Your answers will be automatically submitted should you run out of
              time before finishing.
            </p>
          </div>
        </div>
        <div class="modal-footer exam-begin">
          <button @click="closeBeginDialog" type="button" class="mod-btn leave" data-dismiss="modal">
            Exit
          </button>
          <button type="button" class="mod-btn begin" data-dismiss="modal" @click="beginExam()">
            Begin
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import $ from "jquery";
import RoundLoader from "@/components/loaders/RoundLoader.vue";
import axios from "axios";
import levenshtein from 'fast-levenshtein';
import { insertExamSession, getExamSession, getExamResults, createInProgressResponse, updateInProgressResponse, getInprogressResponse } from "@/services/exam.service";

export default {
  name: "Header",
  emits: ["review", "inProgressResponses", "fillBlankAnswersSet"],
  data() {
    return {
      examAnswers: [],
      minutes: 0,
      duration: 0,
      countdown: null,
      milliseconds: 0,
      loadingResults: false,
      remainingMinutes: 0,
      remainingSeconds: 0,
      totalSeconds: 0,
      examId: 0,
      classId: 0,
      studentId: 0,
      subjectId: 0,
      autoMarks: {
        userTotal: 0,
        examTotal: 0
      },
      examTitle: "",
      examCompleted: false,
      loggedInUser: {},
      countdownEnded: false,
      responsesIntervalId: 0,
      canUpdateAnswers: false,
      questionTypesEnum: {
        MULTIPLE_CHOICE: 1,
        LONG_ANSWER: 2,
        TRUE_OR_FALSE: 3,
        FILL_IN_BLANK: 4,
      },
    };
  },
  components: {
    VueCountdown,
    RoundLoader,
  },
  props: {
    answeredQuestions: {
      type: Array,
    },
    answeredQuestionWatch: {
      type: Number,
      default: 0,
    }
  },
  watch: {
    answeredQuestionWatch() {
      this.canUpdateAnswers = true;
    }
  },
  methods: {
    stopCountdown() {
      console.log("stop");
    },

    async onCountdownEnd() {
      const examSession = await getExamSession(this.examId, this.studentId);
      const examSessionData = examSession.data;
      const canComplete = examSessionData.timeRemaining;
      if (!this.examCompleted && canComplete) {
        this.openResultsModal();
        this.openFinishExam();
      }
    },

    reviewExam() {
      this.$emit("review", true);
    },

    closeResultsDialog() {
      const btn = document.getElementById("hidden-btn");
      btn.click();
      this.logout();
    },

    closeBeginDialog() {
      this.openBeginModal();
      this.logout();
    },

    // Open this modal if exam has alread been written
    openDefaulResultsModal() {
      const finishBtn = document.getElementById("finish-exam");
      const exitBtn = document.getElementById("exit-all");
      const btn = document.getElementById("hidden-btn");

      btn.click();
      finishBtn.classList.add("btn-hidden");
      exitBtn.classList.remove("btn-hidden");
    },

    openResultsModal() {
      this.examCompleted = true;
      const finishBtn = document.getElementById("finish-exam");
      const exitBtn = document.getElementById("exit-all");
      const btn = document.getElementById("hidden-btn");

      this.openFinishExam();
      this.stopTimer();

      this.$refs.countdown.pause();
      this.remainingSeconds = this.$refs.countdown.seconds;
      this.remainingMinutes = this.$refs.countdown.minutes;
      this.totalSeconds = this.$refs.countdown.totalSeconds;
      btn.click();
      finishBtn.classList.add("btn-hidden");
      exitBtn.classList.remove("btn-hidden");
      clearInterval(this.responsesIntervalId);
      this.loadingResults = true;
      this.recordExamAnswers();
    },

    openBeginModal() {
      const btn = document.getElementById("hidden-btn-2");
      btn.click();
    },

    changeToObjectArray(allExamQuestionsResponse, alreadySetTopics) {
      let ourObj = [];

      alreadySetTopics.forEach((topic) => {
        let obj = {
          error: topic.error,
          errorMessage: topic.errorMessage,
          index: topic.index,
          libQuestionsCount: topic.libQuestionsCount,
          fromLibraryIds: topic.fromLibraryIds,
          topicName: topic.topicName,

          questionCards: allExamQuestionsResponse.filter(
            (x) => x.topicId === topic.topicId
          ),

          topicId: topic.topicId,
          subjectId: topic.subjectId,
          gradeId: topic.gradeId,
          examId: topic.examId,
        };
        ourObj.push(obj);
      });

      return ourObj;
    },

    unpackExamQuestions(responseData) {
      let index = 0;
      let cardIndex = 0;
      let fakeContentId = 1;

      let allExamQuestions = [];
      let prev = {};
      if (responseData == null || responseData == undefined) {
        return;
      }

      responseData.forEach((data) => {
        let tmp = prev;

        let array = allExamQuestions[allExamQuestions.length - 1];

        // data.id
        if (tmp.id == fakeContentId) {
          if (data.questions.length > 0) {
            array.content.push({
              type: data.content_type,
              contentIndex: index,
              payload: data.question,
            });
            index++;
          }
        } else {
          index = 0;
          let optionsJson;
          if (data.questionType === this.questionTypesEnum.FILL_IN_BLANK) {
            optionsJson = JSON.parse(data.options);
            data.type = 'D';
          }

          let totalMark;
          if (data.questionType === this.questionTypesEnum.FILL_IN_BLANK && optionsJson && Array.isArray(optionsJson.inputFieldValueOptions)) {
            totalMark = (data.marks_per_option || 2) * optionsJson.inputFieldValueOptions.length;
          } else {
            totalMark = data.marks_per_option || 2;
          }

          let qDoc = {
            id: data.questionId,
            questionType: data.type,
            topicId: data.topicId,
            cardIndex: cardIndex,
            content: [],
            questionUserMark: data.questionUserMark,
            isMarkSaved: false,
            qChecked: true,
            isFeedbackSaved: false,
            questionTotalMark: totalMark,
            questionFeedback: "",
            questionNumber: cardIndex + 1,
            userAnswer: data.userAnswer,
            correctAnswer: data.correctAnswer,
            isAnswerCorrect: data.isAnswerCorrect,
            ...(data.questionType == this.questionTypesEnum.FILL_IN_BLANK && { questions: [] }),
          };
          cardIndex++;

          if (data.questionType === this.questionTypesEnum.FILL_IN_BLANK) {
            let info = data.options && typeof data.options === 'string' ? JSON.parse(data.options) : {};
            qDoc["isHideAllOptions"] = info.isHideAllOptions || false;
            qDoc["inputFieldValueOptions"] = info.inputFieldValueOptions || [];
            qDoc["additionalOptions"] = info.additionalOptions || {};
            qDoc["combinedOptions"] = info.combinedOptions || [];
            data.questions.forEach((question, questionIndex) => {
              qDoc.questions.push({
                type: data.content_type,
                contentIndex: index + questionIndex,
                text: question,
              });
            });

          } else {
            if (data.type == "A") {
              qDoc["options"] = {};
              qDoc["options"] = this.unpackNewOptions(
                data.options,
                data.inputTypes
              );

            } else {
              qDoc["inputType"] = data.inputTypes;
            }         

            if (data.questions.length > 0) {
              // data.questions.forEach(question => {

              for (let i = 0; i < data.questions.length; i++) {
                qDoc.content.push({
                  type: data.contentTypes[i],
                  contentIndex: index,
                  payload: data.questions[i],
                });
                index++;
              }
              // });
            }
          }
          allExamQuestions.push(qDoc);
        }

        fakeContentId++;
        prev = data;
      });

      return allExamQuestions;
    },

    unpackNewQuestions(responseData) {
      let index = 0;
      let cardIndex = 0;
      let examBodyQuestions = [];
      let prev = {};
      if (responseData == null || responseData == undefined) {
        return;
      }
      responseData.map((body) => {
        body.questionsBody.forEach((data) => {
          let tmp = prev;
          let array = examBodyQuestions[examBodyQuestions.length - 1];
          if (tmp.id == data.id) {
            if (data.question.length > 0) {
              if (data.question_type === this.questionTypesEnum.FILL_IN_BLANK) {
                array.questions.push({
                  examQuestionId: data.exam_question_id,
                  availabilityState: "available",
                  edited: false,
                  contentId: data.content_id,
                  type: data.content_type,
                  contentIndex: index,
                  text: data.question,
                });
              } else {
                array.content.push({
                  examQuestionId: data.exam_question_id,
                  availabilityState: "available",
                  edited: false,
                  contentId: data.content_id,
                  type: data.content_type,
                  contentIndex: index,
                  payload: data.question,
                  b64ImageFile: data.question,
                  filename: data.question,
                });
              }
              index++;
            }
          } else {
            index = 0;
            let qDoc = {
              availabilityState: "available",
              edited: false,
              editMode: data.fromlibrary ? "uneditable" : "editable",
              dbType: 1,
              id: data.id,
              fromLibrary: data.fromlibrary,
              // add question type to db
              questionType: data.question_type,
              cardIndex: cardIndex,
              questionNumber: cardIndex + 1,
              content: [],
              correctAnswer: data.answer,
              ...(data.question_type == this.questionTypesEnum.FILL_IN_BLANK && { questions: [] }),
            };
            cardIndex++;
            if (data.question_type === this.questionTypesEnum.FILL_IN_BLANK) {
              let info = data.options && typeof data.options === 'string' ? JSON.parse(data.options) : {};
              qDoc["isHideAllOptions"] = info.isHideAllOptions || false;
              qDoc["inputFieldValueOptions"] = info.inputFieldValueOptions || [];
              qDoc["additionalOptions"] = info.additionalOptions || {};
              qDoc["combinedOptions"] = info.combinedOptions || [];
              qDoc.questions.push({
                examQuestionId: data.exam_question_id,
                availabilityState: "available",
                edited: false,
                contentId: data.content_id,
                type: data.content_type,
                contentIndex: index,
                text: data.question,
              });
              index++;

            } else {
              if (data.type == "A") {
                qDoc["options"] = {};
                qDoc["options"] = {
                  // investigate [TYPE: 1] further
                  type: 1,
                  options: this.unpackNewOptions(data.options, data.input_types),
                };
              } else {
                qDoc["inputType"] = data.input_types;
              }
              if (data.question.length > 0) {
                qDoc.content.push({
                  examQuestionId: data.exam_question_id,
                  availabilityState: "available",
                  edited: false,
                  contentId: data.content_id,
                  type: data.content_type,
                  contentIndex: index,
                  payload: data.question,
                  b64ImageFile: data.question,
                  filename: data.question,
                });
                index++;
              }
            }
            examBodyQuestions.push(qDoc);
          }
          prev = data;
        });
      });

      return examBodyQuestions;
    },

    unpackNewOptions(options, inputTypes) {
      let optionsArray = options.split("<br>");
      let inputTypesArray = inputTypes.split("<br>");
      let questionOptions = [];

      optionsArray.forEach((option, index) => {
        if (option.length != 0) {
          option = option.replace(/\n/g, "");
          let eachSplit = option.split("</b>");
          let optDoc = {
            //optioninputtype
            inputType: inputTypesArray[index],
            letter: eachSplit[0].substring(3).trim(),
            optString: eachSplit[1].trim(),
            placeholder: "Option " + index,
          };
          questionOptions.push(optDoc);
        }
      });
      return questionOptions;
    },

    async getExamBody() {
      let alreadySetTopics = [];
      const storeExamsResponse = await axios.get(
        window.apiUrl + "/exams/stored_exams/" + this.examId,
        { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
      );

      if (storeExamsResponse.status == 200) {
        const storedExamsResults = storeExamsResponse.data;

        let questions = [];
        let topicIds = [];
        let questionIds = [];
        let questionAnswers = [];
        storedExamsResults.map((elem) => {
          if (!questionIds.includes(elem.id)) questionIds.push(elem.id);
          if (!topicIds.includes(elem.topic_id)) topicIds.push(elem.topic_id);
        });
        questionIds.forEach((Id) => {
          const qCard = {
            questionId: Id,
            questionsBody: storedExamsResults.filter((x) => x.id === Id),
          };
          qCard["topicId"] = qCard.questionsBody[0].topic_id;
          qCard["fromLibrary"] = qCard.questionsBody[0].fromlibrary;
          const qAnswer = {
            questionId: Id,
            answer: qCard.questionsBody[0].answer,
          };
          questions.push(qCard);
          questionAnswers.push(qAnswer);
        });
        topicIds.forEach((id, index) => {
          const body = questions.filter((x) => x.topicId === id);
          const libQuestions = questions.filter(
            (x) => x.fromLibrary && x.topicId === id
          );
          // We will make a method for this
          let libQs = [];
          libQuestions.map((x) => {
            if (!libQs.includes(x.questionId)) libQs.push(x.questionId);
          });
          let selectedTpcObj = {
            error: false,
            errorMessage: "",
            index: index,
            libQuestionsCount: libQuestions.length,
            fromLibraryIds: libQs,
            bodyId: body[0].questionsBody[0].id,
            topicName: body[0].questionsBody[0].topic_name,
            questionCards: this.unpackNewQuestions(body),
            topicId: id,
            subjectId: body[0].questionsBody[0].subject_id,
            gradeId: body[0].questionsBody[0].grade_id,
            examId: body[0].questionsBody[0].exam_id,
          };

          alreadySetTopics.push(selectedTpcObj);
        });
      }

      return alreadySetTopics;
    },

    async getMarkScriptBody(examData) {
      let allExamQuestionsResponse = this.unpackExamQuestions(
        examData.attemptedQuestions
      );

      let topicsObj = {
        error: false,
        errorMessage: "",
        index: 0,
        libQuestionsCount: 0,
        fromLibraryIds: [],
        topicName: "Topic 1",
        questionCards: [],
        topicId: 0,
        // subjectId: this.examInfo.subject.subjectId,
        // gradeId: this.examInfo.subject.gradeId,
      };

      let toBeCheckedAlreadySetTopics = await this.getExamBody();
      let alreadySetTopics = toBeCheckedAlreadySetTopics ?? [topicsObj];

      let theeRecord = await this.changeToObjectArray(
        allExamQuestionsResponse,
        alreadySetTopics
      );

      theeRecord = await this.setMarkProgress(theeRecord);

      theeRecord = { ...examData, markScriptBody: theeRecord };

      return theeRecord;
    },

    setMarkProgress(studentRecord) {
      let questionCount = 0;
      let markedQuestions = 0;
      let progressP = 0;
      let checked = false;

      studentRecord.forEach((topic) => {
        topic.questionCards.forEach((card) => {
          if (card.qChecked) {
            markedQuestions++;
          }
          questionCount++;
        });
      });

      if (markedQuestions && questionCount) {
        progressP = (markedQuestions / questionCount) * 100;
      }

      // checked =
      //   parseInt(markedQuestions) ===
      //     parseInt(questionCount)
      //     ? true
      //     : false;

      studentRecord = {
        mProgressTotal: questionCount,
        mProgressNow: markedQuestions,
        mProgressPercentage: progressP.toFixed(2),
        checked: checked,
        body: {
          record: studentRecord,
        },
      };

      return studentRecord;
    },

    async recordExamAnswers() {
      const resultBody = [];

      for (let index = 0; index < this.answeredQuestions.length; index++) {
        const question = this.answeredQuestions[index];
        let isAnswerCorrect = false;
        let correctOptions = [];
        let incorrectOptions = [];
        let questionUserMark = 0;
        let questionTotalMark = 0;
        if (question.questionType === this.questionTypesEnum.FILL_IN_BLANK) {
          let totalInputs = 0; // Initialize total inputs count 
          let fillBlankQuestionAdded = this.examAnswers[index].fillBlankQuestions.length < 1;
          for (let subIndex = 0; subIndex < question.questions.length; subIndex++) {
            const subQuestion = question.questions[subIndex];
            const parser = new DOMParser();
            const doc = parser.parseFromString(subQuestion, 'text/html');
            const inputs = doc.querySelectorAll('input');
            totalInputs += inputs.length; // Update total inputs count
            inputs.forEach((input) => {
              const id = input.getAttribute('data-id');
              const matchingOption = question.inputFieldValueOptions && question.inputFieldValueOptions.find(option => option.id === id);
              if (matchingOption) {
                let isWordCorrect = input.value.trim().toLowerCase() === matchingOption.value.trim().toLowerCase();
                if (!isWordCorrect) {
                  isWordCorrect = this.checkSingleWord(input.value.trim().toLowerCase(), matchingOption.value.trim().toLowerCase());
                }

                if (isWordCorrect) {
                  correctOptions.push(matchingOption);
                  // const updatedInputCorrect = input.cloneNode(true);
                  // updatedInputCorrect.style.border = "2px solid green"; // Add green border to input
                  // question.questions[subIndex] = subQuestion.replace(input.outerHTML, `<span>${updatedInputCorrect.outerHTML}<span style="color:green; font-weight:bold; padding:0 5px;">✓✓</span></span>`);
                  question.questions[subIndex] = subQuestion.replace(input.outerHTML, `<span style="color:green; font-weight:bold; padding:0 5px;">${input.value.trim()} ✓✓</span>`);
                  let marksAwarded = question.marks_per_option || 2;
                  this.autoMarks.userTotal += marksAwarded;
                  questionUserMark += marksAwarded;
                } else {
                  incorrectOptions.push({ id: id, value: input.value });
                  // const updatedInput = input.cloneNode(true);
                  // updatedInput.style.border = "2px solid red"; // Add red border to input
                  // question.questions[subIndex] = subQuestion.replace(input.outerHTML, `<span>${updatedInput.outerHTML}<span style="color:red; font-weight:bold; padding:0 5px;">✗</span></span>`);
                  question.questions[subIndex] = subQuestion.replace(input.outerHTML, `<span style="color:red; font-weight:bold; padding:0 5px;">${input.value.trim()} ✗</span><span style="color:green; font-weight:bold; padding:0 5px;">${matchingOption.value}</span>`);
                }
              }
              this.autoMarks.examTotal += question.marks_per_option || 2;
              questionTotalMark += question.marks_per_option || 2;
            });

            if (fillBlankQuestionAdded) {
              this.examAnswers[index].fillBlankQuestions.push({ text: question.questions[subIndex] });
            }
          }
          // isAnswerCorrect = correctOptions.length === totalInputs; // Compare correct options count with total inputs count
          isAnswerCorrect = correctOptions.length > 0 && totalInputs > 0; // Compare correct options count with total inputs count

          localStorage.setItem("examAnswers", JSON.stringify(this.examAnswers));
          this.$emit('fillBlankAnswersSet', this.examAnswers);
        } else {
          if (question.inputType.split("<br>").length > 1) {
            if (
              this.examAnswers[index].answer.toLowerCase() ===
              question.answer.toLowerCase()
            ) {
              isAnswerCorrect = true;
              let marksAwarded = question.marks_per_option || 2;
              this.autoMarks.userTotal += marksAwarded;
              questionUserMark += marksAwarded;
            }
          } else {
            if (question.inputType === "plain") {


              // Normalize responses
              const normalizedUserResponse = question.answer.trim().toLowerCase();
              const normalizedCorrectAnswer = this.examAnswers[index].answer.trim().toLowerCase();

              // Check if the user response is not empty
              if (normalizedUserResponse.length > 0) {
                // Split and filter the correct answer to accurately check for single-word answers
                const wordsInCorrectAnswer = normalizedCorrectAnswer.split(' ').filter(word => word.length > 0);
                const wordsInUserAnswer = normalizedUserResponse.split(' ').filter(word => word.length > 0);

                if (wordsInCorrectAnswer.length === 1 || wordsInCorrectAnswer.length === 2) {

                  if (this.examAnswers[index].answer.trim().toLowerCase() === question.answer.trim().toLowerCase()) {
                    isAnswerCorrect = true;
                    let marksAwarded = question.marks_per_option || 2;
                    this.autoMarks.userTotal += marksAwarded;
                    questionUserMark += marksAwarded;
                  } else {

                    await axios.post(`${window.apiUrl}/exams/gpt/compare-answers`, {
                      question: question.questions.join('\n'),
                      correctAnswer: this.examAnswers[index].answer,
                      userAnswer: question.answer,
                      maxMark: question.marks_per_option || 2
                    }, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }).then(response => {

                      // Convert the response to a number
                      const responseNumber = parseFloat(response.data);

                      // Ensure the response is a valid number within the expected range
                      const validResponse = !isNaN(responseNumber) && responseNumber >= 0 && responseNumber <= (question.marks_per_option || 2) ? responseNumber : null;

                      if (validResponse !== null) {
                        if (validResponse > 0)
                          isAnswerCorrect = true;
                        else
                          isAnswerCorrect = false;
                        this.autoMarks.userTotal += validResponse;
                        questionUserMark += validResponse;
                      }
                    }).catch(error => {
                      console.log(error);

                      if (wordsInUserAnswer.length > wordsInCorrectAnswer.length || wordsInUserAnswer.length < wordsInCorrectAnswer.length) {
                        const { isUserAnswerCorrect, markAllocated } = this.checkLongAnswer(normalizedUserResponse, normalizedCorrectAnswer, question.marks_per_option || 2);
                        if (isUserAnswerCorrect) {
                          isAnswerCorrect = isUserAnswerCorrect;
                          this.autoMarks.userTotal += markAllocated;
                          questionUserMark += markAllocated;
                        }
                      } else {

                        // For single-word answers, use Levenshtein directly
                        const isWordCorrect = this.checkSingleWord(normalizedUserResponse, normalizedCorrectAnswer);
                        if (isWordCorrect) {
                          isAnswerCorrect = true;
                          let marksAwarded = question.marks_per_option || 2;
                          this.autoMarks.userTotal += marksAwarded;
                          questionUserMark += marksAwarded;
                        }
                      }
                    });

                  }

                } else {

                  await axios.post(`${window.apiUrl}/exams/gpt/compare-answers`, {
                    question: question.questions.join('\n'),
                    correctAnswer: this.examAnswers[index].answer,
                    userAnswer: question.answer,
                    maxMark: question.marks_per_option || 2
                  }, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }).then(response => {

                    // Convert the response to a number
                    const responseNumber = parseFloat(response.data);

                    // Ensure the response is a valid number within the expected range
                    const validResponse = !isNaN(responseNumber) && responseNumber >= 0 && responseNumber <= (question.marks_per_option || 2) ? responseNumber : null;

                    if (validResponse !== null) {
                      if (validResponse > 0)
                        isAnswerCorrect = true;
                      else
                        isAnswerCorrect = false;
                      this.autoMarks.userTotal += validResponse;
                      questionUserMark += validResponse;
                    }
                  }).catch(error => {
                    console.log(error);

                    // New fallback comparison, For longer answers!!
                    const { isUserAnswerCorrect, markAllocated } = this.checkLongAnswer(normalizedUserResponse, normalizedCorrectAnswer, question.marks_per_option || 2);
                    if (isUserAnswerCorrect) {
                      isAnswerCorrect = isUserAnswerCorrect;
                      this.autoMarks.userTotal += markAllocated;
                      questionUserMark += markAllocated;
                    }
                  });

                }
              }
            } else if (question.inputType === "math") {
              // compare latex texts
              console.log("work on the math");
            }
          }
          this.autoMarks.examTotal += question.marks_per_option || 2;
          questionTotalMark += question.marks_per_option || 2;
        }
        const resObj = {
          questionId: question.questionId,
          userAnswer: question.answer,
          topicId: question.topicId,
          topicName: question.topicName,
          correctAnswer: this.examAnswers[index].answer,
          isAnswerCorrect: isAnswerCorrect,
          questionUserMark: questionUserMark,
          questionTotalMark: questionTotalMark,
          inputTypes: question.inputTypes,
          questionType: question.questionType,
          marks_per_option: question.marks_per_option,
          options: question.options,
          type: question.type,
          subjectId: question.subjectId,
          subjectName: question.subjectName,
          contentTypes: question.contentTypes,
          questions: question.questions,
          correctOptions: correctOptions,
          incorrectOptions: incorrectOptions,
        };
        if (question.questionType === this.questionTypesEnum.FILL_IN_BLANK) {
          Object.assign(resObj, {
            correctOptions: correctOptions,
            incorrectOptions: incorrectOptions,
          });
        }

        resultBody.push(resObj);
      }

      localStorage.setItem('resultBody', JSON.stringify(resultBody));
      const attemptBody = {
        examId: this.examId,
        studentId: this.studentId,
        classId: this.classId,
        subjectId: this.subjectId,
        attemptedQuestions: resultBody,
      };

      const attemptBodyWithMarkScriptBody = await this.getMarkScriptBody(
        attemptBody
      );
      axios
        .post(
          `${window.apiUrl}/exams/exam_attempts`,
          attemptBodyWithMarkScriptBody,
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
        )
        .then((res) => {
          if (res.status == 200 && res.data.recordInserted) {
            this.recordExamResults();
          }
        });
    },

    recordExamResults() {
      const resultsBody = {
        examTotal: this.autoMarks.examTotal,
        userTotal: this.autoMarks.userTotal,
        timeSpent: ((this.duration * 60 - this.totalSeconds) / 60).toFixed(2),
        examId: this.examId,
        studentId: this.studentId,
      };
      axios
        .post(`${window.apiUrl}/exams/exam_results`, resultsBody,
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } })
        .then((res) => {
          if (res.status == 200 && res.data.recordInserted) {
            this.loadingResults = false;
          }
        });
    },

    checkSingleWord(userResponse, correctAnswer) {
      const distance = levenshtein.get(userResponse, correctAnswer);
      const threshold = 0.8; // Adjust based on needs
      const similarity = 1 - distance / Math.max(userResponse.length, correctAnswer.length);
      return similarity >= threshold;
    },

    checkLongAnswer(userResponse, correctAnswer, availableMarks) {
      // Define a list of stop words to ignore
      const stopWords = ['a', 'the', 'and', 'of', 'in', 'to', 'it', 'is', 'for', 'on', 'that', 'by', 'this', 'with', 'i', 'you', 'not', 'or', 'be', 'are', 'from', 'at', 'as', 'your', 'all', 'have', 'new', 'more', 'an', 'was', 'we', 'will', 'home', 'can', 'us', 'about', 'if', 'page', 'my', 'has', 'search', 'free', 'but', 'our', 'one', 'other', 'do', 'no', 'information', 'time', 'they', 'site', 'he', 'up', 'may', 'what', 'which', 'their', 'news', 'out', 'use', 'any', 'there', 'see', 'only', 'so', 'his', 'when', 'contact', 'here', 'business', 'who', 'web', 'also', 'now', 'help', 'get', 'pm', 'view', 'online', 'first', 'am', 'been', 'would', 'how', 'were', 'me', 's'];
      // Split and Filter out stop words and empty strings from user and correct answers
      const wordsUser = userResponse.split(' ').filter(word => word && !stopWords.includes(word.toLowerCase()));
      const wordsCorrect = correctAnswer.split(' ').filter(word => word && !stopWords.includes(word.toLowerCase()));

      //check significant words, or use a different threshold
      let significantMatches = 0;
      for (let word of wordsCorrect) {
        for (let userWord of wordsUser) {
          const distance = levenshtein.get(word, userWord);
          const similarity = 1 - distance / Math.max(word.length, userWord.length);
          if (similarity >= 0.8) { // Adjust threshold as needed
            significantMatches++;
            break; // Move to the next word after the first match
          }
        }
      }

      const matchRatio = significantMatches / wordsCorrect.length;
      let markAllocated = 0;
      if (matchRatio >= 0.75) {
        markAllocated = availableMarks; // Give full marks if matchRatio is 0.75 or above
      } else if (matchRatio >= 0.5) {
        markAllocated = Math.round(availableMarks * 0.5); // Give half of the available marks if matchRatio is above 0.5 but below 0.75
      } // Give 0 marks if matchRatio is 0.5 or below
      return {
        isUserAnswerCorrect: matchRatio >= 0.5,
        markAllocated: markAllocated,
      };
    },

    openFinishExam() {
      $(".finish-exam").toggleClass("scale-out");
    },

    startTimer() {
      let timeBar = document.getElementById("timer-bar");
      const progress1 = document.getElementById("p1");
      let seconds = this.duration * 60;
      let elapsedTime = this.$refs.elaspedTime;
      let activeCount = 0;
      this.countdown = setInterval(() => {
        this.minutes--;
        activeCount = ((seconds - this.minutes) * 100) / seconds;
        elapsedTime.style.width = 100 - activeCount + "%";
        timeBar.style.width = activeCount + "%";
        if (this.minutes <= 120) {
          elapsedTime.style.background = "red";
          progress1.style.background = "red";
        }
        if (this.minutes === 0) {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.countdown);
    },

    logout() {
      const redirect = localStorage.getItem('redirect');
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("examAnswers");
      localStorage.removeItem("examQuestions");
      localStorage.removeItem("resultBody");
      localStorage.removeItem("redirect");
      localStorage.setItem('tokenUsed', true);
      if (redirect) {
        window.location.href = redirect;
        return;
      }
      this.$router.go(-2);
    },

    async beginExam() {
      this.startTimer();
      this.$refs.countdown.restart();
      this.$refs.countdown.start();
      if (this.loggedInUser.newExamSession) {
        await insertExamSession({ examId: this.examId, studentId: this.studentId });
        await createInProgressResponse({ examId: this.examId, studentId: this.studentId, response: this.answeredQuestions, });
        this.responsesIntervalId = setInterval(async () => {
          if (this.canUpdateAnswers) {
            await updateInProgressResponse({ examId: this.examId, studentId: this.studentId, response: this.answeredQuestions, })
            this.canUpdateAnswers = false;
          }
        }, 10000);
      }
    },
  },
  async mounted() {
    clearInterval(this.responsesIntervalId);
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const questions = JSON.parse(localStorage.getItem("examQuestions"));
    const answers = JSON.parse(localStorage.getItem("examAnswers"));

    this.examAnswers = answers;
    this.studentId = this.loggedInUser.id;
    this.examId = questions[0].questionsBody[0].exam_id;

    const examResultPayload = await getExamResults(this.examId, this.studentId);
    const examResultData = examResultPayload.data;
    if (examResultData) {
      const examTaker = examResultData.find((x) => x.student_id === this.studentId);
      this.autoMarks.userTotal = examTaker.user_total;
      this.autoMarks.examTotal = examTaker.exam_total;
      this.milliseconds = 0;
      this.minutes = 0;
      const inprogressResponse = await getInprogressResponse(this.examId, this.studentId);
      if (inprogressResponse.data) this.$emit('inProgressResponses', inprogressResponse.data);
      this.openDefaulResultsModal();
      return;
    }

    const examSession = await getExamSession(this.examId, this.studentId);
    const examSessionData = examSession.data;
    if (examSessionData) {
      const inprogressResponse = await getInprogressResponse(this.examId, this.studentId);
      const updatedUserPayload = { ...this.loggedInUser, newExamSession: examSessionData.newExamSession, timeRemaining: examSessionData.timeRemaining };
      localStorage.setItem('loggedInUser', JSON.stringify(updatedUserPayload));
      this.loggedInUser = updatedUserPayload;
      if (inprogressResponse.data) this.$emit('inProgressResponses', inprogressResponse.data);
      if (!this.loggedInUser.newExamSession)
        this.responsesIntervalId = setInterval(async () => {
          if (this.canUpdateAnswers) {
            this.canUpdateAnswers = false;
            await updateInProgressResponse({ examId: this.examId, studentId: this.studentId, response: this.answeredQuestions, });
          }
        }, 10000);
    }
    this.subjectId = questions[0].questionsBody[0].subject_id;
    this.classId = questions[0].questionsBody[0].class_id;
    this.examTitle = questions[0].questionsBody[0].title;
    this.duration = questions[0].questionsBody[0].duration;
    this.minutes = this.loggedInUser.newExamSession ? this.duration * 60 : this.loggedInUser.timeRemaining * 60;
    this.milliseconds = this.minutes * 1000;

    if (this.loggedInUser.newExamSession)
      this.openBeginModal();
    else {
      if (this.loggedInUser.timeRemaining <= 0) return;
      else {
        setTimeout(() => {
          this.beginExam();
        }, 50);
      }
    }
  },
};
</script>
<style scoped>
nav {
  border-bottom: 1px solid #0000000f;
  width: calc(100% - 23vw);
  position: sticky;
  top: 0;
  transition: all 0.3s;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  left: 23vw;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  background: #fffffff7;
  padding: 1%;
}

.exam-begin {
  justify-content: center;
}

.btn-hidden {
  display: none;
}

.scale-transition {
  transition: transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
}

.scale-transition.scale-out {
  transform: scale(0);
  transition: transform 0.2s !important;
}

.scale-transition.scale-in {
  transform: scale(1);
}

.finish-menu {
  text-align: -webkit-center;
  position: absolute;
  right: 1.5rem;
  margin-top: 5rem;
  z-index: 1;
  width: 18vw;
  background: white;
  padding: 0.5rem;
}

.time {
  margin: 0 1rem;
}

.progres-bar {
  background: #00b871;
  height: 0.15rem;
}

.progres-bar1 {
  background: rgb(251 251 251);
  height: 0.15rem;
  width: 0;
  max-width: 100%;
}

.progres {
  background: rgb(251 251 251);
  height: 0.15rem;
  width: auto;
  min-width: 10rem;
  max-width: 17rem;
  align-self: center;
}

.progres1 {
  background: #00b871;
  height: 0.15rem;
  width: auto;
  min-width: 10rem;
  max-width: 17rem;
  align-self: center;
}

.count-down {
  align-self: center;
  display: flex;
}

.main-comps {
  display: flex;
  justify-content: space-around;
}

.finish-btn {
  background: #2f2e41;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: x-small;
  height: 35px;
  font-size: 600;
}

.exit-btn {
  background: #2f2e41;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: x-small;
  height: 35px;
  font-size: 600;
  width: 3rem;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  padding-top: 8%;
}

.action-buttons>button {
  border: 1px solid #e5e8ea;
  width: 40%;
  border-radius: 4px;
}

button.yes {
  background: #ca2727d1;
  color: white;
}

button.no {
  background: #000000d1;
  color: white;
}

#hidden-btn-2,
#hidden-btn {
  display: none;
}

.loader {
  padding: 5%;
}

.calc-results {
  padding: 5%;
}

.modal-header {
  background: #2f2e41;
  color: #fff;
}

.modal-footer {
  border-top: none;
}

.mod-btn {
  border: 1px solid #e5e8ea;
  border-radius: 4px;
  padding: 3px 16px;
}

button.mod-btn.review {
  background: #00b871;
  color: #fff;
}

button.mod-btn.begin {
  border: none;
  border-radius: 1.5rem;
  background: #00b871;
  color: #fff;
  font-weight: 600;
  width: 28%;
  height: 3rem;
  cursor: pointer;
}

button.mod-btn.exit {
  background: #2f2e41;
  color: #fff;
}

button.mod-btn.leave {
  /* margin-top: 4rem; */
  border: none;
  border-radius: 1.5rem;
  background: #2f2e41;
  color: #fff;
  font-weight: 600;
  width: 28%;
  height: 3rem;
  cursor: pointer;
}

.score {
  font-size: xx-large;
}

span.myscore {
  font-weight: bold;
  font-size: larger;
}

.logo {
  width: 8rem;
}

@media (min-width: 1340px) {

  .progres,
  .progres1 {
    width: 17rem;
  }

}

@media (max-width: 820px) {
  .finish-menu {
    width: auto;
  }

  .main-comps .logo {
    width: 6rem;
  }

  .main-comps {
    width: 100%;
  }

  .progres,
  .progres1 {
    display: none;
  }
}
</style>