import IExamSession from "@/shared/models/exam.model";
import axios from "axios";
const apiUrl = (window as any).apiUrl;

const insertExamSession = async (sessionBody: IExamSession) => {
    try {
        const res = await axios.post(`${apiUrl}/exams/createExamSession`, sessionBody, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
        return res;
    } catch (error) {
        //Developer note: Implement error handler
        return error;
    }
}

const getExamSession = async (examId: number, studentId: number) => {
    try {
        const res = await axios.get(`${apiUrl}/exams/getExamSessionById/${examId}/${studentId}`, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
        return res;
    } catch (error) {
        //Developer note: Implement error handler
        return error;
    }
}

const getExamResults = async (examId: number, studentId: number) => {
    try {
        const res = await axios.get(`${apiUrl}/exams/exam_results/examwithstudent/${examId}/${studentId}`, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
        return res;
    } catch (error) {
        return error;
    }
}

const createInProgressResponse = async (inProgressData: any) => {
    try {
        const res = await axios.post(`${apiUrl}/exams/createInProgressResponse`, inProgressData, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
        return res;
    } catch (error) {
        return error;
    }
}

const updateInProgressResponse = async (inProgressData: any) => {
    try {
        const res = await axios.put(`${apiUrl}/exams/update/updateInProgressResponse`, inProgressData, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
        return res;
    } catch (error) {
        return error;
    }
}

const getInprogressResponse = async (examId: number, studentId: number) => {
    try {
        const res = await axios.get(`${apiUrl}/exams/getInProgressResponse/${examId}/${studentId}`, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
        return res;
    } catch (error) {
        return error;
    }
}

export {
    insertExamSession,
    getExamSession,
    getExamResults,
    createInProgressResponse,
    updateInProgressResponse,
    getInprogressResponse,
}