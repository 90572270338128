<template>
  <div>
    <div id="round1">
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoundLoader",
};
</script>

<style scoped>
#round1 {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  -webkit-animation: round1-rotate 2s infinite linear;
  animation: round1-rotate 2s infinite linear;
}

#round1 span {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  background-color: #00b871;
  border-radius: 50%;
  -webkit-animation: round1-bounce 2s infinite ease-in-out;
  animation: round1-bounce 2s infinite ease-in-out;
}

#round1 span:nth-child(2) {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes round1-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round1-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes round1-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes round1-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>