
import { defineComponent } from "vue";
import IQuestionsBody from "@/models/QuestionsBody.Model";

export default defineComponent({
  name: "QuestionsBank",
  props: {
    examQuestions: {
      type: Object as () => IQuestionsBody[],
    },
    answeredQuestions: {
      type: Array,
    },
    updateAnsweredQusetions: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    updateAnsweredQusetions() {
      const tempAnsweredQs = this.answeredQuestions as any;
      if (this.examQuestions === undefined) return;
      this.examQuestions.forEach((question, index: number) => {
        if (this.answeredQuestions === undefined) return;
        const btnId = document.getElementById(`q-${index}`);
        if (
          question.questionId === tempAnsweredQs[index].questionId &&
          tempAnsweredQs[index].answered
        ) {
          btnId?.classList.add("q-answered");
        } else btnId?.classList.remove("q-answered");
      });
    },
  },
  methods: {
    goToQuestion(index: number) {
      setTimeout(() => {
        this.smoothScroll(`q-card-${index}`);
      }, 300);
    },
    smoothScroll(id: string) {
      document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
});
