
import Header from "../../components/header/Header.vue";
import SideBar from "../../components/sidebar/Sidebar.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePage",
  data() {
    return {
      answeredQuestions: [],
      answeredQuestionsWithFillBlank: [],
      update: 0,
      updateReview: 0,
      inprogressResponses: [],
    }
  },
  components: {
    Header,
    SideBar,
  },
  methods: {
    reviewExam(canReview: boolean) {
      if (canReview) this.updateReview += 1;
    },

    emitedAnswers(answers: any) {
      this.answeredQuestions = answers;
      this.update += 1;
    },

    emitedFillblankAnswers(answers: any) {
      this.answeredQuestionsWithFillBlank = answers;
      this.update += 1; 
    },

    setExistingResponses(responses: any) {
      this.inprogressResponses = responses;
    }
  },
  mounted() {
    this.$router.push({ name: "ExamBody" });
  },
});
