import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideBar, {
      class: "sidebar",
      answeredQuestions: _ctx.answeredQuestions,
      questionsUpdate: _ctx.update
    }, null, 8, ["answeredQuestions", "questionsUpdate"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        class: "header",
        answeredQuestionWatch: _ctx.update,
        answeredQuestions: _ctx.answeredQuestions,
        onReview: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reviewExam($event))),
        onInProgressResponses: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setExistingResponses($event))),
        onFillBlankAnswersSet: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitedFillblankAnswers($event)))
      }, null, 8, ["answeredQuestionWatch", "answeredQuestions"]),
      _createVNode(_component_router_view, {
        onAnswered: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emitedAnswers($event))),
        reviewExam: _ctx.updateReview,
        inProgressResponses: _ctx.inprogressResponses,
        fillBlankInReviewAnswersSet: _ctx.answeredQuestionsWithFillBlank
      }, null, 8, ["reviewExam", "inProgressResponses", "fillBlankInReviewAnswersSet"])
    ])
  ]))
}