
import { defineComponent } from "vue";
import QuestionsBank from "../exam/QuestionsBank.vue";
// import IExamQuestions from "src/models/ExamQuestion.Model";
import $ from "jquery";
import IQuestionsBody from "@/models/QuestionsBody.Model";

export default defineComponent({
  name: "Sidebar",
  data() {
    return {
      examQuestions: [] as IQuestionsBody[],
      grade: 0,
      subject: "",
      examTopic: "",
      className: "",
      examiner: "",
      update: 0
    };
  },
  components: {
    QuestionsBank,
  },
  props: {
    answeredQuestions: {
      type: Array,
    },
    questionsUpdate: {
      type: Number,
      default: 0
    }
  },
  watch: {
    questionsUpdate() {
      this.update += 1;
    }
  },

  mounted() {
    $(() => {
      let questions = JSON.parse(
        localStorage.getItem("examQuestions") as string
      );
      this.examQuestions = questions.map((x: any) => ({
        questionId: x.questionId,
        questionsBody: x.questionsBody.map((question: any) => ({
          id: question.id,
          question: question.question,
          answer: question.answer,
          marks: 2,
          contentType: question.content_type,
          examSubject: question.subject_name,
          examTopic: question.title,
          grade: question.grade,
          className: question.class_name,
        }))
      }));
      this.grade = this.examQuestions[0].questionsBody[0].grade;
      this.subject = this.examQuestions[0].questionsBody[0].examSubject;
      this.examTopic = this.examQuestions[0].questionsBody[0].examTopic;
      this.className = this.examQuestions[0].questionsBody[0].className;
      this.examiner = `${questions[0].questionsBody[0].name} ${questions[0].questionsBody[0].surname}`
    });
  },
});
