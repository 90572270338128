<template>
  <div class="row container">
    <div class="col-2">
      <button class="add-item" @click="addStep">
        <fa-icon icon="plus" />
      </button>
    </div>

    <div class="col-8 step-input">
      <draggable class="list-group" tag="transition-group" :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }" v-model="stepsList" v-bind="dragOptions" @start="drag = true" @end="drag = false" item-key="stepId">
        <template #item="{ element }">
          <li class="list-group-item">
            <i :class="element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
              " @click="element.fixed = !element.fixed" aria-hidden="true"></i>
            <div class="header">
              <fa-icon icon="times" @click="removeAt(element.stepId)" />
            </div>
            <math-field :id="`math-field-answer-${element.stepId}-card-${element.cardIndex}`" virtualKeyboardMode="manual"
              @input="($event) => updateEquation($event, element)">{{
                element.equation }}</math-field>
          </li>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import $ from "jquery";

export default {
  name: "MathCompnent",
  display: "Transitions",
  order: 7,
  components: {
    draggable,
  },
  data() {
    return {
      mathInputConfig: {
        smartMode: true,
        virtualKeyboardMode: "manual",
      },
      lastStepId: 0,
      stepsList: [],
      drag: false,
    };
  },
  props: {
    inProgressSteps: {
      type: Array,
    },
    cardIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    inProgressSteps: {
      handler(newValue) {
        $(() => {
          this.stepsList = newValue.stepsArray;
          this.stepsList.forEach((step) => {
            const id = `math-field-answer-${step.stepId}-card-${step.cardIndex}`;
            const element = document.getElementById(id);
            if (element)
              element.value = step.equation;
          });
        });
      },
      deep: true,
    }
  },
  methods: {
    updateEquation(event, element) {
      element.equation = event.target.value;
      this.$emit("mathInputs", JSON.stringify(this.stepsList));
    },
    addStep() {
      this.lastStepId += 1;
      this.stepsList.push({ equation: "", stepId: this.lastStepId, cardIndex: this.cardIndex });
    },
    removeAt(stepId) {
      const index = this.stepsList.findIndex((x) => x.stepId === stepId);
      this.stepsList.splice(index, 1);
      this.$emit("mathInputs", JSON.stringify(this.stepsList));
    },
  },
  mounted() {
    if (this.inProgressSteps)
      this.stepsList = this.inProgressSteps
    else this.stepsList.push({ equation: "", stepId: 0, cardIndex: this.cardIndex })
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
  padding: 0.2rem;
}

.list-group-item i {
  cursor: pointer;
}

.container {
  padding: 0 1rem 1rem 1rem;
}

.header {
  font-size: x-small;
  text-align: end;
}

.add-item {
  background: transparent;
  outline: none;
  border-radius: 50%;
  border: none;
  box-shadow: #4e4b4b9e 1px 1px 3px;
  width: 2.5rem;
  height: 2.5rem;
}

math-field {
  display: block;
}

math-field:focus-within {
  outline: 2px solid #0c9826;
  border-radius: 4px;
}

@media (max-width: 820px) {
  .step-input {
    width: 100%;
    max-width: 100%;
    flex: 82%;
  }
}
</style>