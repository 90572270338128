
const MathLive = require("mathlive");
import { defineComponent } from "vue";
import $ from "jquery";
import MathComponent from "@/components/exam/MathComponent.vue";
import IMathStep from '@/shared/models/math-step.model';
import IStudentResponses from '@/shared/models/student-responses.model';
import IExamAnswers from "@/shared/models/exam-answers.model";

export default defineComponent({
  name: "ExamBody",
  components: {
    MathComponent,
  },
  data() {
    return {
      examAnswers: [] as IExamAnswers[],
      examQuestions: [],
      answeredQuestions: [] as any,
      review: false,
      studentResponse: [] as IStudentResponses[],
      inProgressMathSteps: [] as any,
      questionTypesEnum: {
        MULTIPLE_CHOICE: 1,
        LONG_ANSWER: 2,
        TRUE_OR_FALSE: 3,
        FILL_IN_BLANK: 4,
      },
    };
  },
  props: {
    reviewExam: {
      type: Number,
      default: 0,
    },
    inProgressResponses: {
      type: Object
    },
    fillBlankInReviewAnswersSet: {
      type: Array
    }
  },
  watch: {
    reviewExam() {
      const examBody = document.getElementById("exam-body");
      this.studentResponse = JSON.parse(localStorage.getItem('resultBody') as string);
      examBody?.classList.add("review-mode");
      this.review = true;
      $(function () {
        MathLive.renderMathInElement("exam-body");
      });
    },
    inProgressResponses(newValue) {
      this.answeredQuestions = newValue.inProgressResponse;
      this.answeredQuestions.forEach((item: any, index: number) => {
        if (item.questionType === 4) {
          item.questions.forEach((question: string, questionIndex: number) => {
            const fbId = `card${item.index}fb-question${questionIndex}`;
            const fbElement = document.getElementById(fbId) as HTMLElement | null;
            if (fbElement) {
              fbElement.innerHTML = question;
            }
          });

        } else {

          if (item.inputTypes === 'math') {
            const stepsArray = item.answer ? JSON.parse(item.answer) : [{ equation: "", stepId: 0, cardIndex: index }];
            this.inProgressMathSteps.push({ index: index, stepsArray });
          }
          else {
            const id = `answer-field-card-${index}`;
            const element = document.getElementById(id) as HTMLInputElement | null;
            if (element) {
              element.value = item.answer;
            }
          }
        }
      });
      this.$emit("answered", this.answeredQuestions);
    },
    fillBlankInReviewAnswersSet(newValue) {
      this.examAnswers = newValue;
    }
  },
  methods: {
    handleInlineInput(event: CustomEvent, cardIndex: number, questionIndex: number) {
      const { id, value } = event.detail;

      let question = this.answeredQuestions[cardIndex].questions[questionIndex];

      let parser = new DOMParser();
      let doc = parser.parseFromString(question, 'text/html');

      // Find the input by its unique ID and update its value
      let inputElement = doc.querySelector(`input[data-id="${id}"]`) as HTMLInputElement;
      if (inputElement) {
        inputElement.value = value; // Directly update the input element's value to ensure it reflects the new input value
        inputElement.setAttribute('value', value); // Also update the value attribute to ensure it persists in the HTML
      }

      // Check if at least one input value is not null or empty
      const inputs = doc.querySelectorAll('input');
      const isAnyInputFilled = Array.from(inputs).some(input => input.value.trim() !== '');
      if (isAnyInputFilled) {
        this.answeredQuestions[cardIndex].answered = true;
      }

      // Serialize the document back to HTML and update the question text when focus out of the input field
      this.answeredQuestions[cardIndex].questions[questionIndex] = doc.body.innerHTML;
      this.$emit("answered", this.answeredQuestions);
    },

    openMathSteps(index: number) {
      const answerBtn = document.getElementById(`answer-btn-${index}`);
      const mathSteps = document.getElementById(`math-step-card-${index}`);

      if (answerBtn?.classList.contains("hidden")) {
        answerBtn.classList.remove("hidden");
        mathSteps?.classList.add("hidden");
      } else if (mathSteps?.classList.contains("hidden")) {
        answerBtn?.classList.add("hidden");
        mathSteps.classList.remove("hidden");
      }
    },

    emitAnswer(index: number, isMath = false, mathAnswers = '') {
      const id = `answer-field-card-${index}`;
      const answer = isMath ? mathAnswers : (document.getElementById(id) as HTMLInputElement).value;
      this.answeredQuestions[index].answer = answer;
      this.answeredQuestions[index].answered = !isMath ?
        this.answeredQuestions[index].answer.length > 0 : JSON.parse(mathAnswers).every((item: IMathStep) => item.equation !== '');
      this.$emit("answered", this.answeredQuestions);
    },

    emitMathInput(event: any, index: number) {
      this.emitAnswer(index, true, event);
    },

    emitFlag(index: number) {
      this.answeredQuestions[index].flagged = this.answeredQuestions[index]
        .flagged
        ? false
        : true;
      this.$emit("answered", this.answeredQuestions);
    },

    arrangeOptions(options: string, inputs: string) {
      const optionsArray = options.split("<br>");
      const inputsArray = inputs.split("<br>");
      let result: any[] = [];
      optionsArray.forEach((option: string, index: number) => {
        // eslint-disable-next-line no-unused-vars
        if (inputsArray[index] !== "math")
          option = option.replace(
            /<\/b>/g,
            '</b>  <span class="space"></span>'
          );
        else
          option = option
            .replace(/<b>/g, "")
            .replace(/<\/b>/g, "")
            .substring(1);
        result.push(option);
      });
      return result;
    },

    getOptionLetter(index: number): string {
      let res: string = "";
      switch (index) {
        case 0:
          res = "A";
          break;
        case 1:
          res = "B";
          break;
        case 2:
          res = "C";
          break;
        case 3:
          res = "D";
          break;
        default:
          break;
      }
      return res;
    },

    getContentTypes(examBodyQuestions: any[]): string[] {
      let res: string[] = [];
      examBodyQuestions.forEach((q) => {
        res.push(q.content_type);
      });
      return res;
    },

    getContentQuestions(examBodyQuestions: any[]): string[] {
      let res: string[] = [];
      examBodyQuestions.forEach((q) => {
        res.push(q.question);
      });
      return res;
    },

    checkCorrectAnswer(questionId: number, itemId: string, correctAnswer = '') {
      const cardElem = document.getElementById(itemId);
      const foundItem = this.examAnswers.find((item: any) => item.questionId === questionId);
      let res;
      if (this.studentResponse && !foundItem) {
        res = this.studentResponse.find((item) => item.questionId === questionId)?.isAnswerCorrect ?? false;
      } else {
        if (foundItem && foundItem.questionUserMark > 0) {
          res = true;
        } else {

          if (this.studentResponse && Array.isArray(this.studentResponse)) {
            res = this.studentResponse.find((item: any) => item.questionId === questionId)?.isAnswerCorrect ?? false;
          } else if (this.answeredQuestions && Array.isArray(this.answeredQuestions)) {
            res = this.answeredQuestions.find((item: any) => item.questionId === questionId)?.answer.toLowerCase() === correctAnswer.toLowerCase();
          } else {
            res = false;
          }
        }

      }
      if (res) cardElem?.classList.add('correct-answer-card');
      else cardElem?.classList.add('incorrect-answer-card');
    }
  },
  mounted() {
    let answers = JSON.parse(localStorage.getItem("examAnswers") as string);
    let questions = JSON.parse(localStorage.getItem("examQuestions") as string);

    this.examAnswers = answers;
    this.examQuestions = questions.map((x: any) => {
      const questionsBody = x.questionsBody.map((question: any, index: number) => {
        let modifiedQuestion = question.question;
        let userFillBlankResponseIndex = answers.findIndex((answer: any) => answer.questionId === x.questionId);
        let userFillBlankResponse = answers[userFillBlankResponseIndex];

        if (x.questionType === this.questionTypesEnum.FILL_IN_BLANK) {

          if (userFillBlankResponse.fillBlankQuestions?.length) {

            const parser = new DOMParser();
            const mQuestion = userFillBlankResponse.fillBlankQuestions[index].text
            const optionsJson = JSON.parse(question.options);
            const doc = parser.parseFromString(mQuestion, 'text/html');
            const inputs = doc.querySelectorAll('input');
            inputs.forEach((input) => {
              const id = input.getAttribute('data-id');
              const matchingOption = optionsJson.inputFieldValueOptions.find((option: any) => option.id === id);

              if (matchingOption) {
                if (input.value.trim().toLowerCase() !== matchingOption.value.trim().toLowerCase()) {
                  answers[userFillBlankResponseIndex].fillBlankQuestions[index].text = mQuestion.replace(input.outerHTML, `<span style="color:red; font-weight:bold; padding:0 5px;">${input.value.trim()} ✗</span><span style="color:green; font-weight:bold; padding:0 5px;">${matchingOption.value}</span>`) ?? mQuestion;
                } else {
                  answers[userFillBlankResponseIndex].fillBlankQuestions[index].text = mQuestion.replace(input.outerHTML, `<span style="color:green; font-weight:bold; padding:0 5px;">${input.value.trim()} ✓✓</span>`);
                }

              }
            });

            let match;
            const text = answers[userFillBlankResponseIndex].fillBlankQuestions[index].text;
            const regex = /<span style="color:red; font-weight:bold; padding:0 5px;">(.*?) ✗<\/span>|<span style="color:green; font-weight:bold; padding:0 5px;">(.*?) ✓✓<\/span>/g;
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = question.question;
            const beforeReviewinputs = tempDiv.querySelectorAll('input');
            let inputIndex = 0;
            while ((match = regex.exec(text)) !== null && inputIndex < beforeReviewinputs.length) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (match.index === regex.lastIndex) {
                regex.lastIndex++;
              }
              // The result can be accessed through the `match`-Array
              const extractedText = match[1] || match[2];

              beforeReviewinputs[inputIndex].setAttribute('value', extractedText ? extractedText : "");
              inputIndex++;
              modifiedQuestion = tempDiv.innerHTML;
            }

          } else {

            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = question.question;
            const inputs = tempDiv.querySelectorAll('input');
            inputs.forEach(input => {
              input.removeAttribute('value');
            });
            modifiedQuestion = tempDiv.innerHTML;

          }
        }

        const foundAnswer = answers.find((qa: any) => qa.questionId === question.id);
        const questionTotalMark = foundAnswer && (foundAnswer.questionTotalMark < 0 || foundAnswer.questionTotalMark === undefined || foundAnswer.questionTotalMark === null) ? 2 : foundAnswer?.questionTotalMark;

        const baseQuestion = {
          id: question.id,
          question: modifiedQuestion,
          answer: question.answer,
          marks: questionTotalMark,
          contentType: question.content_type,
          examSubject: question.subject_name,
          examTopic: question.title,
          grade: question.grade,
          className: question.class_name,
          topicId: question.topic_id,
          topicName: question.topic_name,
          inputTypes: question.input_types.split("<br>"),
          type: question.type,
          subjectId: question.subject_id,
          subjectName: question.subject_name,
        };

        if (x.questionType !== this.questionTypesEnum.FILL_IN_BLANK) {
          return { ...baseQuestion, options: this.arrangeOptions(question.options, question.input_types) };
        }
        return baseQuestion;
      });

      let optionsJson = {};
      if (x.questionType === this.questionTypesEnum.FILL_IN_BLANK) {
        try {
          optionsJson = JSON.parse(x.questionsBody[0].options);
        } catch (error) {
          console.error('Failed to parse options:', error);
          optionsJson = {}; // Setting to an empty object as a fallback
        }
      }

      return {
        questionId: x.questionId,
        questionType: x.questionType,
        ...optionsJson,
        questionsBody,
      };
    });


    let questionIds: any[] = [];
    questions.map((elem: any, index: number) => {
      if (!questionIds.includes(elem.questionId)) {
        let optionsJson = {};
        if (elem.questionsBody[0].question_type === this.questionTypesEnum.FILL_IN_BLANK) {
          try {
            optionsJson = JSON.parse(elem.questionsBody[0].options);
          } catch (error) {
            console.error('Failed to parse options for question type 4:', error);
            optionsJson = {}; // Setting to an empty object as a fallback
          }
        }

        const obj = {
          index: index,
          questionId: elem.questionId,
          answered: false,
          answer: "",
          flagged: false,
          inputType: elem.questionsBody[0].input_types,
          questionType: elem.questionsBody[0].question_type,
          marks_per_option: elem.questionsBody[0].marks_per_option,
          inputTypes: elem.questionsBody[0].input_types,
          topicId: elem.questionsBody[0].topic_id,
          topicName: elem.questionsBody[0].topic_name,
          options: elem.questionsBody[0].options,
          type: elem.questionsBody[0].type,
          subjectId: elem.questionsBody[0].subject_id,
          subjectName: elem.questionsBody[0].subject_name,
          contentTypes: this.getContentTypes(elem.questionsBody),
          questions: this.getContentQuestions(elem.questionsBody).map(question => {
            let modifiedQuestion = question;
            if (elem.questionType === this.questionTypesEnum.FILL_IN_BLANK) {
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = question;
              const inputs = tempDiv.querySelectorAll('input');
              inputs.forEach(input => {
                input.removeAttribute('value');
              });
              modifiedQuestion = tempDiv.innerHTML;
            }
            return modifiedQuestion;
          }),
          ...optionsJson,
        };
        this.answeredQuestions.push(obj);
      }
    });

    $(function () {
      MathLive.renderMathInElement("exam-body");
    });
    this.$emit("answered", this.answeredQuestions);
  },
});
